export const isRouteMatched = (name) => {
  return name === useRoute().name.replace(/^[a-z]+_/, "");
};

export const isTimePassed = (endAt) => {
  return endAt < new Date().getTime() / 1000;
};

export const isPromotionEnded = (category) => {
  return isTimePassed(category.promotion.period.endAt);
};
